/* 아래 세 개 추가한 것. 아니면 위아래로 나타나는 + z-index 추가 */
.arrow {
  position: absolute;
  cursor: pointer;
  opacity: 0.9;
}

.mainNextArrow {
  top: 44%;
  right: 60px;
}

.mainPrevArrow {
  z-index: 3000;
  top: 44%;
  left: 60px;
}

.mobileMainNextArrow {
  top: 43%;
  right: 28px;
}

.mobileMainPrevArrow {
  z-index: 3000;
  top: 43%;
  left: 20px;
}

.SlideSection {
  overflow: hidden;
}