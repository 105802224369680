.ql-editor {
  min-height: 50px;
}

.ql-editor[contenteditable="true"] {
  max-height: 400px;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
}

iframe {
  aspect-ratio: 16/9;
}

.ql-editor {
  padding: 15px;
}


@media (min-width:1280px) {
  iframe {
    min-width: 500px;
  }  
  .ql-editor img {
    max-width: 1000px;
  }

  .ql-editor p {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (min-width:1920px) {
  iframe {
    min-width: 500px;
  }  
  .ql-editor img {
    max-width: 1000px;
  }

  .ql-editor p {
    font-size: 20px;
    line-height: 32px;
  }
}

@media (max-width:440px) {
  iframe {
    min-width: 250px;
  }  
  .ql-editor img {
    max-width: 300px;
  }

  .ql-editor p {
    font-size: 16px;
    line-height: 25.6px;
  }
}

