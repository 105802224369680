.pagination { 
    display: flex;
    justify-content: center; 
    margin-top: 15px; 
  } 
  ul { 
    list-style: none; 
    padding: 0; 
  } 

  ul.pagination li { 
    display: inline-block;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border-radius: 4px;
    margin-left: 0px;
    margin-right: 0px;
  } 
  
  ul.pagination li:first-child{ 
    border-radius: 5px 0 0 5px; 
  } 
  
  ul.pagination li:last-child{ 
    border-radius: 0 5px 5px 0; 
  } 
  
  ul.pagination li a { 
    text-decoration: none; 
    color: #666666;
    font-size: 20px; 
    font-weight: 400;
  } 
  
  ul.pagination li a:hover, 
  ul.pagination li.active a { 
    color: #333333;
    font-size: 20px; 
    font-weight: 700;
  } 
  
  .page-selection { 
    width: 48px; 
    height: 30px; 
    color: #333333;
  }
  
  .disabled a svg {
    color: #DADADA;
  }

  @media (max-width: 440px) {
    ul.pagination li a { 
      text-decoration: none; 
      color: #666666;
      font-size: 16px; 
      font-weight: 400;
    } 
    
    ul.pagination li a:hover, 
    ul.pagination li.active a { 
      color: #333333;
      font-size: 16px; 
      font-weight: 700;
    } 
  }